<template>
  <div class="col-lg-4 padCustom">
    <div class="sectionThreeBoxWrap">
      <div class="insideItemBoxWrap">
        <div
          v-if="loading"
          class="d-flex flex-column"
          style="width: 100%;"
        >
          <div
            class="d-flex flex-row"
            style="width: 100%; justify-content: space-between;"
          >
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="45%"
              height="140px"
            />
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="45%"
              height="140px"
            />
          </div>
          <div
            class="d-flex flex-row"
            style="width: 100%; justify-content: space-between;"
          >
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="45%"
              height="140px"
            />
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="45%"
              height="140px"
            />
          </div>
        </div>
        <draggable
          v-else
          v-model="dashboardSort.section_3"
          class="section-three row"
          group="table"
          :class="isDisable ? 'section-three-in-active' : ''"
          :style="{ flex: isSectionOne_Two && 1 }"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_3"
            :key="i"
            :loading="loading"
            :class="getClassName()"
          >
            <!-- col-lg-6 -->
            <div
              :class="[
                'smallSectionTwoBox',
                row.keys[0] === 'savings' ? 'sortingDiv' : '',
                getStyle(),
              ]"
            >
              <div class="valueBox">
                <p>
                  <span>
                    {{
                      $t(`units.${row.keys[0]}`) | currencyFormat(authUser)
                    }}</span>{{ getValue([row?.keys[0]]) }}
                </p>
              </div>
              <div
                v-if="row.keys[0] == 'savings'"
                :class="['iconBox', getIconColor(row.keys[0])]"
              >
                <Icons :name="getIconName(row.keys[0])" />
              </div>
              <div class="contentBox">
                {{ row.card_name }}
              </div>
              <div
                v-if="row.keys[0] == 'electricity_cost'"
                class="barChart lineChartBasic"
              >
                <ApexLineChart
                  :data="(getElectricCost.electric_cost_chart_data) ? getElectricCost.electric_cost_chart_data : []"
                  :width="'100%'"
                  :height="setHeigth(row.keys[0])"
                />
              </div>
              <div
                v-else-if="row.keys[0] == 'fuel_cost'"
                class="barChart"
              >
                <ApexLineWithLabelsChart
                  :data="(getFuelCost.fuel_cost_chart_data) ? getFuelCost.fuel_cost_chart_data : []"
                  :width="'100%'"
                  :height="setHeigth(row.keys[0])"
                />
              </div>
              <!-- <div
                v-else-if="row.keys[0] == 'average_soc'"
                class="barChart"
              >
                <ApexAreaChartBasic
                  :width="'100%'"
                  :height="setHeigth(row.keys[0])"
                />
              </div> -->
              <div
                v-else-if="row.keys[0] == 'savings'"
                class="barChart"
              >
                <ApexBarChart
                  :data="(getSavingCost.saving_cost_chart_data) ? getSavingCost.saving_cost_chart_data : []"
                  :width="'100%'"
                  :height="setHeigth(row.keys[0])"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    Icons: () => import("@/components/base/icons.vue"),
    ApexBarChart: () => import("@/components/sections/ApexBarChart.vue"),
    // ApexAreaChartBasic: () => import("@/components/sections/ApexAreaChartBasic.vue"),
    ApexLineWithLabelsChart: () =>
      import("@/components/sections/ApexLineWithLabelsChart.vue"),
    ApexLineChart: () => import("@/components/sections/ApexLineChart.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      iconName: "",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
      getFuelCost: "dashboard/getFuelCost",
      getElectricCost: "dashboard/getElectricCost",
      getSavingCost: "dashboard/getSavingCost",
      getSoc: "dashboard/getSoc",
    }),
    isSectionOne_Two() {
      return !this.dashboardSort?.section_1 && !this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },

  methods: {
    getValue(key) {
      if (key == "fuel_cost") {
        return this.getFuelCost.fuel_cost;
      } else if (key == "electricity_cost") {
        return this.getElectricCost.electricity_cost;
      } else if (key == "average_soc") {
        return this.getSoc.average_soc;
      } else if (key == "savings") {
        return this.getSavingCost.savings;
      }
    },
    getIconColor(key) {
      if (key == "average_soc") {
        return "blueBg";
      } else {
        return "greenBg";
      }
    },
    getIconName(key) {
      if (key == "average_soc") {
        return "battery-efficiency";
      } else {
        return "savings";
      }
    },
    updateOrder() {
      const params = {
        section: "section_3",
        sort: true,
        item: this.dashboardSort.section_3,
        vehicle_tech: this.vehicleTech,
        // from: val.moved.oldIndex,
        // to: val.moved.newIndex,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },

    getClassName() {
      if (
        this.dashboardSort.section_3 &&
        this.dashboardSort.section_3.length <= 2
      ) {
        return "col-lg-12";
      }
      return "col-lg-6";
    },
    getStyle() {
      return this.dashboardSort.section_3 &&
        this.dashboardSort.section_3.length == 1
        ? "minHeight"
        : "";
    },

    // else if (key === 'fuel_cost') {
    //   return this.dashboardSort.section_3.length === 1 ? "300px" : "165px";
    // } else if (key === 'average_soc') {
    //   return this.dashboardSort.section_3.length === 1 ? "320px" : "120px";
    // }

    setHeigth(key) {
      if (key === 'electricity_cost' || key === 'fuel_cost') {
        if (this.dashboardSort.section_3.length === 1) {
          return "350px";
        } else if (this.dashboardSort.section_3.length === 2) {
          return "165px";
        } else {
          return "170px";
        }
      } else if (key === "savings") {
        if (this.dashboardSort.section_3.length === 1) {
          return "340px";
        } else if (this.dashboardSort.section_3.length === 2) {
          return "136px";
        } else {
          return "120px";
        }
      }
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-three
  width: 35%
  .v-card
    cursor: all-scroll
    background: transparent !important

@media only screen and (max-width: 960px)
  .section-three
    width: 100%
</style>